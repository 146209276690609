import { React, Fragment, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  useHistory,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './pages/home/Home';
import Features from './pages/features/Features';
import Pricing from './pages/pricing/Pricing';
import FreeDemo from './pages/freeDemo/FreeDemo';
import ContactUs from './pages/contactUs/ContactUs';
import Routes from './routes/Routes';
import './root.css';
import './App.css';
import cateratLogo from './assets/Caterat_Logo_Outlined.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { faPhone } from '@fortawesome/free-solid-svg-icons'

function App() {
  const [isMobileMenuPopUp, setIsMobileMenuPopUp] = useState();
  const openMobileMenu = () => setIsMobileMenuPopUp(true);
  const closeMobileMenu = () => {
    setIsMobileMenuPopUp(false);
    // console.log("close!")
  };
  const directToWhatsapp = () => window.open("https://api.whatsapp.com/send?phone=85256629356", "_blank");
  const directToPhoneCall = () => window.location.href = 'tel:+852-56629356';
  const directToHome = () => window.location.href = `/`;
  const now = new Date()
  const bannerDate = new Date((new Date(now.setMonth(now.getMonth()+3))).setDate(0))
  return (
    <Router>
      
      <div className={`app ${isMobileMenuPopUp&&"show-overlay"}`}>
        <header className="header">
          <div className="mobile-nav-menu-wrap">
            <div onClick={closeMobileMenu} className={`backdrop ${isMobileMenuPopUp&&"show-overlay"}`}/>
            <div className={`mobile-nav-menu-overlay ${isMobileMenuPopUp&&"show-overlay"}`}>
              <div onClick={closeMobileMenu} className="backdrop-container"></div>
              <div className="mobile-nav-menu-container">
                <ul className={`mobile-nav-menu ${!isMobileMenuPopUp&&"close"}`}>
                  <li className="nav-item">
                    <button className="nav-close-btn" onClick={closeMobileMenu}>
                      <i className="material-icons-outlined">
                      close
                      </i>
                    </button>
                  </li>
                  <NavBarLinks closeMobileMenu={closeMobileMenu}/>
                </ul>
              </div>
            </div>
          </div>
          <nav className="navbar">
            {/* <a href="#" className="nav-logo">Caterat</a> */}
            <div onClick={directToHome} className="caterat-logo" style={{backgroundImage: `url(${cateratLogo})`}}/>
            <div className="flex-column">
              {/* <a href="#" className="nav-contact">查詢電話&Whatsapp</a> */}
              <div className="nav-contact">
                <i onClick={directToWhatsapp} class="material-icons-outlined">
                whatsapp
                </i>
                <div onClick={directToWhatsapp}  className="info flex-column">
                  <h5>預約免費上門示範</h5>
                  <h5>5662-9356</h5>
                </div>
              </div>
              <div>
                <ul className="nav-menu">
                  <NavBarLinks/>
                </ul>
              </div>
            </div>
            <button className="nav-menu-btn" onClick={openMobileMenu}>
              <i class="material-icons-outlined">
              menu
              </i>
            </button>
            {/* <a onClick={directToLogin} className="nav-link" id="login-btn">註冊 / 登入</a> */}
          </nav>
          <div className="banner">限時優惠：即日起至{bannerDate.getDate()}/{bannerDate.getMonth()+1}/{bannerDate.getUTCFullYear()}，簽訂3年合約，即可以每年$3,388優惠價訂閱餐飲系統</div>
        </header>
        <section className="main">
          {/* <Route exact path="/" render={() => <Redirect to="/home" />} /> */}
          <Switch>
            <Route exact path="/">
              <Home/>
            </Route>
            <Route path="/features">
              <Features/>
            </Route>
            <Route path="/pricing">
              <Pricing/>
            </Route>
            <Route path="/contact-us">
              <ContactUs/>
            </Route>
            <Route path="/" render={() => <Redirect to="/" />} />
          </Switch>

          {/* <div className="content-grid-1">
            <div className="content">
              A
            </div>
            <div className="content">
              B
            </div>
            <div className="content">
              C
            </div>
            <div className="content">
              D
            </div>
          </div>
          <div className="content-grid-2">
            <div className="grid-2-row">
              <div className="word">
                word
              </div>
              <div className="pic">
                pic
              </div>
            </div>
            <div className="grid-2-row">
              <div className="pic">
                pic
              </div>
              <div className="word">
                word
              </div>
            </div>
          </div> */}
        </section>
        <footer>
          <div className="footer">
              <ul className="nav-menu">
                <NavBarLinks/>
              </ul>
              <p>© R CUBE APP STUDIO LIMITED</p>
          </div>
          <div className="mobile-footer">
            <div className="footer-content">
              <button className="footer-btn" onClick={directToPhoneCall}>
              <FontAwesomeIcon size="2x" icon={faPhone} />
                電話
              </button>
              <button className="footer-btn" onClick={directToWhatsapp}>
                <FontAwesomeIcon size="2x" icon={faWhatsapp} />
                Whatsapp
              </button>
              {/* <a href="/demo">
                <button className="footer-btn">
                  <i className="block material-icons-outlined">
                    screenshot
                  </i>
                  免費體驗
                </button>
              </a> */}
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

const NavBarLinks = ({closeMobileMenu=()=>{}}) => {

  // const directToIntroFunctions = () => history.push({pathname: `/features`});
  // const directToFreeDemo = () => history.push({pathname: `/demo`});
  // const directToPrice = () => history.push({pathname: `/pricing`});
  // const directToContactUs = () => history.push({pathname: `/contactUs`});
  const closeMenuAndScroll = () => {
    closeMobileMenu();
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
  const directToLogin = () => window.open(Routes.login, "_blank");
  return (
    <Fragment>
      <li className="nav-item">
          <Link onClick={closeMenuAndScroll} to="/" className="nav-link">首頁</Link>
      </li>
      <li className="nav-item">
          <Link onClick={closeMenuAndScroll} to="/features" className="nav-link">系統功能</Link>
      </li>
      <li className="nav-item">
          <Link onClick={closeMenuAndScroll} to="/pricing" className="nav-link">收費</Link>
      </li>
      {/* <li className="nav-item">
          <Link onClick={closeMenuAndScroll} to="/demo" className="nav-link">免費體驗</Link>
      </li> */}
      <li className="nav-item">
          <Link onClick={closeMenuAndScroll} to="/contact-us" className="nav-link">聯絡我們</Link>
      </li>
      <li className="nav-item">
          <a onClick={directToLogin} className="nav-link" id="login-btn">註冊 / 登入</a>
      </li>
    </Fragment>
  );
}

export default App;
