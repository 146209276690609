import '../../root.css';
import './Features.css';
import '../../App.css';
import dineInCheckout from '../../assets/dine_in_checkout.webp';
import waiterMakeOrder from '../../assets/waiterMakeOrder.png';
import kitchenManage from '../../assets/kitchenManage.webp';
import backendManage from '../../assets/backendManage.png';
import dataTable from '../../assets/dataTable.png';
import orderManage from '../../assets/orderManage.png';
import discountSetUp from '../../assets/discountSetUp.png';
import ipad from '../../assets/Ipad.png';
import floorPlan from '../../assets/floorPlan.webp';
import menuSetUp from '../../assets/menuSetUp.webp';
import iOS from '../../assets/iOS_1.png';
import webDineIn from '../../assets/webDineIn.webp';
import webTakeaway from '../../assets/webTakeaway.webp';
import selfTakeaway from '../../assets/selfTakeaway.webp';
import handheld from '../../assets/handheld.webp';
const imgUrlMap = {
    dineInCheckout: "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2F%E4%BB%98%E6%AC%BE%E7%B5%90%E5%B8%B3.png?alt=media&token=50e19f19-16d0-4c99-8a06-6ef0d807e9f7",
    waiterMakeOrder: "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2F%E5%A0%82%E9%A3%9F%E4%BE%8D%E6%87%89%E9%BB%9E%E9%A4%90.png?alt=media&token=d2d9e46c-513b-4730-b8fd-fea19f46a773",
    kitchenManage: "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2F%E5%BB%9A%E6%88%BF%E7%AE%A1%E7%90%86%20with%20iPad.png?alt=media&token=29ac374f-4825-44f1-ac16-462975cac120",
    backendManage: "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2F%E5%BE%8C%E5%8F%B0%E7%AE%A1%E7%90%86.png?alt=media&token=3b446371-30ed-4357-baa5-867e3b5d7c9e",
    dataTable: "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2F%E7%B5%B1%E8%A8%88%E5%A0%B1%E8%A1%A8.png?alt=media&token=c3df4a9b-e469-44be-a82e-ca0b0f7b405e",
    orderManage: "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2F%E8%A8%82%E5%96%AE%E7%AE%A1%E7%90%86.png?alt=media&token=4262fc80-844c-4995-bffa-28bd1282ad8f",
    discountSetUp: "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2F%E8%A8%AD%E7%AB%8B%E6%8A%98%E6%89%A3%E5%84%AA%E6%83%A0.png?alt=media&token=5d8c9b2f-4438-496f-b029-26665ebd2ff2",
    floorPlan: "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2F%E9%A4%90%E9%A3%B2%E7%B3%BB%E7%B5%B1%E5%BA%A7%E4%BD%8D%E5%AE%89%E6%8E%92.gif?alt=media&token=e20bd1d4-2833-49ea-b7e6-3125f6862546",
    menuSetUp: "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2F%E9%A4%90%E9%A3%B2%E7%B3%BB%E7%B5%B1%E9%9B%BB%E5%AD%90%E9%A4%90%E7%89%8C.png?alt=media&token=adecdf49-0cb9-44bb-8ddb-ea1943f0838e",
    iOS: "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2FiOS%201.png?alt=media&token=2c11c5ad-765d-4d7e-84f3-bba74c1dec22",
    webDineIn: "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2F%E5%A0%82%E9%A3%9F%E8%87%AA%E5%8A%A9%E9%BB%9E%E9%A4%90%20with%20iPad%202.PNG?alt=media&token=cefc4877-4b8f-41d5-a8ca-275b2c01edff",
    webTakeaway: "https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/public-website%2F%E5%A4%96%E8%B3%A3%E9%81%99%E8%B7%9D%E9%BB%9E%E9%A4%90.gif?alt=media&token=6c64da7a-b9f3-4357-8110-f8732a2a9945",
}
function Features(){
    return (
        <div className="features flex-column">
            <div className="img-title-container">
                <div className="img-title-wrapper">
                    <h1>Caterat POS | 系統功能</h1>
                    <h1>功能全面，輕鬆建立你的雲端餐飲系統</h1>
                </div>
            </div>
            <h2 className="features-title">​基本功能</h2>
            <div className="container content-grid-2">
                <div/>
                <div className="content-grid-2-row">
                    {/* <div className="image-container" style={{backgroundImage: `url(${imgUrlMap.menuSetUp})`}}/> */}
                    <div className="image-container ipad-border" style={{backgroundImage: `url(${menuSetUp})`}}/>
                    <div className="word-container">
                        <div>
                            <h2>
                            電子餐牌  
                            </h2>
                            <p className="list-item">可加入圖片、編輯餐牌菜單、設定堂食和外賣價格。</p>
                            <p className="list-item">可設定餐點供應日子、時段。</p>
                            <p className="list-item">可隨時為餐點上架、下架。</p>
                            <p className="list-item">可設定不同服務費用，例如加一服務費、切餅費、開瓶費。</p>
                            <p className="list-item">可編輯不同餐點口味選項，例如：少冰、走冰、少甜、走甜、大辣、中辣、小辣等等。</p>
                            <p className="list-item">支援其他外賣平台專屬餐單。</p>
                        </div>
                    </div>
                </div>
                <div className="content-grid-2-row">
                    <div className="word-container">
                        <div>
                            <h2>
                            座位安排  
                            </h2>
                            <p className="list-item">接待員可透過餐飲系統處理座位安排，包括：開枱、搭枱、轉枱。</p>
                            <p className="list-item">不同顏色標記餐枱狀態：未開枱、未下單、已下單。</p>
                            <p className="list-item">顯示每張枱的用餐時間，方便管理座位。</p>
                        </div>
                    </div>
                    {/* <div className="image-container" style={{backgroundImage: `url(${floorPlan})`}}>
                        <div className="gif-container" id="floor-plan-gif">

                        </div>
                    </div> */}
                    <div className="image-container ipad-border" style={{backgroundImage: `url(${floorPlan})`}}>
                        {/* <div className="gif-container" style={{backgroundImage: `url(${floorPlan})`}}>

                        </div> */}
                    </div>
                </div>
                <div className="content-grid-2-row">
                    <div className="image-container ipad-border" style={{backgroundImage: `url(${dineInCheckout})`}}/>
                    <div className="word-container">
                        <div>
                            <h2>
                            付款結帳 
                            </h2>
                            <p className="list-item">可給予客人不同的優惠折扣。</p>
                            <p className="list-item">可直接修改帳單的餐點價格。</p>
                            <p className="list-item">結帳出錯時可復原帳單。</p>
                            <p className="list-item">可根據餐廳已申請的線下支付平台去記錄不同付款方式。</p>
                            <p className="list-item">可協助餐廳申請卡機。</p>
                        </div>
                    </div>
                </div>
                <div className="content-grid-2-row">
                    <div className="word-container">
                        <div>
                            <h2>
                            出單管理
                            </h2>
                            <p className="list-item">可設定不同的出單位置（客單、廚房單、水吧單）。</p>
                            <p className="list-item">廚房單、水吧單有不同字體大小選擇，亦可設定簡稱，方便員工閱讀，加快出餐流程。</p>
                            <p className="list-item">顧客點餐後，訂單即時送達廚房，廚師可根據訂單顯示準備菜式。</p>
                        </div>
                    </div>
                    <div className="image-container ipad-border" style={{backgroundImage: `url(${kitchenManage})`}}/>
                </div>
            </div>
            <div className="divider"/>
            <h2 className="features-title">​支援多種點餐模式</h2>
            <div className="container content-grid-2">
                <div/>
                <div className="content-grid-2-row">
                <div className="image-container ipad-border" style={{backgroundImage: `url(${waiterMakeOrder})`}}/>

                    <div className="word-container">
                        <div>
                            <h2>
                            侍應點餐－主程式點餐
                            <br/>
                            (POS Cashier App)
                            </h2>
                            <p className="list-item">侍應可透過主程式為外賣或堂食顧客點餐。</p>
                            <p className="list-item">下單界面簡潔易用，讓侍應能快速為顧客點餐。</p>
                        </div>
                    </div>
                </div>
                <div className="content-grid-2-row">
                    <div className="word-container">
                        <div>
                            <h2>
                            侍應點餐－手提下單程式點餐
                            <br/>
                            (POS Handheld App) 
                            </h2>
                            <p className="list-item">侍應可透過手提下單程式為堂食顧客點餐。</p>
                            <p className="list-item">針對提升堂食下單速度而設。</p>
                            <p className="list-item">手提下單程式支援Andriod 和 iOS 智能裝置。</p>
                            <p className="list-item">如員工使用自攜的智能裝置，更能節省成本。</p>
                        </div>
                    </div>
                    <div className="iphone-container" style={{backgroundImage: `url(${handheld})`}}/>
                </div>
                <div className="content-grid-2-row">
                    <div className="image-container ipad-border" style={{backgroundImage: `url(${webDineIn})`}}/>
                    <div className="word-container">
                        <div>
                            <h2>
                                堂食二維碼(QR Code)自助點餐

                            </h2>
                            <p>顧客可透過手機掃瞄二維碼 (QR Code) 即時自助點餐。</p>
                        </div>
                    </div>
                </div>
                <div className="content-grid-2-row">
                    <div className="word-container">
                        <div>
                            <h2>
                                外賣遙距點餐
                                <br />
                                <span className="title-remark">*需開啟網上外賣自取平台</span>
                            </h2>
                            <p className="list-item">餐廳可將二維碼 (QR Code)展示於網站、社交媒體平台或廣告單張，讓客人可遙距下單付款。</p>
                            <p className="list-item">當客人點餐後，廚房會即時收到訂單，然後開始製作食物。</p>
                        </div>
                    </div>
                    <div className="image-container">
                        <div className="iphone-container" style={{backgroundImage: `url(${webTakeaway})`}}></div>
                    </div>
                </div>
                <div className="content-grid-2-row">
                    <div className="image-container ipad-border" style={{backgroundImage: `url(${selfTakeaway})`}}/>
                    <div className="word-container">
                        <div>
                            <h2>
                                外賣自助點餐
                                <br />
                                <span className="title-remark">*需開啟網上外賣自取平台</span>
                            </h2>
                            <p className="list-item">餐廳可將二維碼 (QR Code)張貼於店門外。</p>
                            <p className="list-item">外賣客人可透過手機掃瞄二維碼 (QR Code) 即時自助點餐。</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="divider"/>
            <h2 className="features-title">後台功能</h2>
            <div className="container content-grid-2">
                <div/>
                <div className="content-grid-2-row">
                    <div className="image-container ipad-border" style={{backgroundImage: `url(${orderManage})`}}/>
                    <div className="word-container">
                        <div>
                            <h2>
                            訂單管理  
                            </h2>
                            <p>侍應可透過餐飲系統查閱、修改和取消訂單</p>
                        </div>
                    </div>
                </div>
                <div className="content-grid-2-row">
                    <div className="word-container">
                        <div>
                            <h2>
                            後台管理 
                            </h2>
                            <p className="list-item">無需專業知識也可輕鬆透過後台管理餐廳業務，包括修改和更新餐單、樓面平面圖、收款方式、查閱交易記錄、編輯職員權限。</p>
                            {/* <p className="list-item">可下載CSV檔，查閱過往所有交易記錄。</p> */}
                        </div>
                    </div>
                    <div className="image-container ipad-border" style={{backgroundImage: `url(${backendManage})`}}/>
                </div>
                <div className="content-grid-2-row">
                    <div className="image-container ipad-border" style={{backgroundImage: `url(${dataTable})`}}/>
                    <div className="word-container">
                        <div>
                            <h2>
                            統計報表
                            </h2>
                            <p>可統計餐廳的生意額和折扣額、最受歡迎的餐點、外賣、堂食和網上下單的比例、最受歡迎的付款模式等，讓餐廳制訂不同銷售策略。</p>
                        </div>
                    </div>
                </div>
                <div className="content-grid-2-row">
                    <div className="word-container">
                        <div>
                            <h2>
                            設立折扣優惠 
                            </h2>
                            <p>可設定不同優惠折扣，例如：外賣優惠、熟客優惠​。</p>
                        </div>
                    </div>
                    <div className="image-container ipad-border" style={{backgroundImage: `url(${discountSetUp})`}}/>
                </div>

                {/* <div className="content-grid-2-row">
                    <div className="image-container" style={{backgroundImage: `url(${iOS})`}}/>
                    <div className="word-container">
                        <div>
                            <h2>
                            支援iOS智能裝置
                            </h2>
                            <p>POS系統可於App Store 免費下載。</p>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default Features;