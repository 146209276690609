import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../root.css';
import './Home.css';
import '../../App.css';
import headerPic from '../../assets/home_header.jpg';
import {useState, useRef, useEffect} from 'react';

function Home(){
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return (
        <div className="home flex-column">
            <div className="container home-title-grid">
                <h1 className="title">一站式雲端餐飲系統<br/>
                &emsp;&emsp;&emsp;&emsp;&emsp;進入新世代落單模式</h1>
                <div className="home-title-img" style={{backgroundImage: `url(${headerPic})`}}/>
                <h6 className="subtitle">提高營運效益　享受更大回報</h6>
            </div>
            <div className="divider"/>
            <div className="container home-content">
                <div className="home-content title">
                    {/* <h5>
                    我們提供一套價廉物美的雲端餐飲系統<br/>以取代傳統的POS餐飲系統，<br/>
                    幫助不同類型的餐廳應付日常營運需要，<br/>
                    解決現有問題、提高效率、改善客戶體驗、<br/>加強競爭力，在同業中突圍而出！
                    </h5> */}
                    <h2>​為什麼選擇 Caterat 餐飲系統</h2>
                </div>
                <div className="list-tile">
                    <h4>
                        1. 收費
                    </h4>
                    <table cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                {width>768 && <td class="empty-cell"></td>}
                                <td>Caterat {width<=768 && <br/>}餐飲系統</td>
                                <td>其他{width<=768 && <br/>}餐飲系統</td>
                            </tr>
                        </thead>
                        <tbody>
                            {width<=768 && <tr><td colspan="2" className="subtitle">基本月費</td></tr>}
                            <tr>
                                {width>768 && <td className="subtitle">基本月費</td>}
                                <td>低至每月 $499</td>
                                <td>每月約 $780 - $3,500</td>
                            </tr>
                            {width<=768 && <tr><td colspan="2" className="subtitle">侍應手提下單程式</td></tr>}
                            <tr>
                                {width>768 && <td className="subtitle">侍應手提下單程式</td>}
                                <td>基本月費已包含</td>
                                <td>需額外收費</td>
                            </tr>
                            {width<=768 && <tr><td colspan="2" className="subtitle">堂食二維碼自助點餐程式</td></tr>}
                            <tr>
                                {width>768 && <td className="subtitle">堂食二維碼自助點餐程式</td>}
                                <td>基本月費已包含</td>
                                <td>需額外收費</td>
                            </tr>
                            {width<=768 && <tr><td colspan="2" className="subtitle">硬件</td></tr>}
                            <tr>
                                {width>768 && <td className="subtitle">硬件</td>}
                                <td>成本價出售</td>
                                <td>漲價出售以賺取更多利潤</td>
                            </tr>
                            {width<=768 && <tr><td colspan="2" className="subtitle">售後服務</td></tr>}
                            <tr>
                                {width>768 && <td className="subtitle">售後服務</td>}
                                <td>免費</td>
                                <td>可能需額外繳付年費或逐次收費</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="list-tile">
                    <h4>
                        2. 點餐功能
                    </h4>
                    <h5>
                        A) 侍應手提下單程式
                    </h5>
                    <table cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                {width>768 && <td class="empty-cell"></td>}
                                <td>Caterat {width<=768 && <br/>}餐飲系統</td>
                                <td>其他{width<=768 && <br/>}餐飲系統</td>
                            </tr>
                        </thead>
                        <tbody>
                            {width<=768 && <tr><td colspan="2" className="subtitle">支援裝置</td></tr>}
                            <tr>
                                {width>768 && <td className="subtitle">支援裝置</td>}
                                <td>任何一部iOS 或 Andriod 的智能電話</td>
                                <td>部份需定制的手提機，按手提機數目收費</td>
                            </tr>
                            {width<=768 && <tr><td colspan="2" className="subtitle">介面</td></tr>}
                            <tr>
                                {width>768 && <td className="subtitle">介面</td>}
                                <td>加入自動化技術，簡單易用，下單快速</td>
                                <td>部份定制手提機有過多不必要功能，影響下單流暢性</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <h5>
                        B) 線上外賣自取平台
                    </h5>
                    <ul>
                        <li>解決繁忙時間因未能接聽外賣電話或外賣隊伍太長所導致的客人流失</li>
                        <li>點餐過程由顧客自助處理，避免錯誤落單，同時減少樓面人手的工作量</li>
                    </ul>
                    <table cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                {width>768 && <td class="empty-cell"></td>}
                                <td>Caterat {width<=768 && <br/>}餐飲系統</td>
                                <td>其他{width<=768 && <br/>}餐飲系統</td>
                            </tr>
                        </thead>
                        <tbody>
                            {width<=768 && <tr><td colspan="2" className="subtitle">獨立性</td></tr>}
                            <tr>
                                {width>768 && <td className="subtitle">獨立性</td>}
                                <td>每間餐廳有專屬的線上外賣自取平台</td>
                                <td>可能會同時顯示其他餐廳</td>
                            </tr>
                            {width<=768 && <tr><td colspan="2" className="subtitle">介面</td></tr>}
                            <tr>
                                {width>768 && <td className="subtitle">介面</td>}
                                <td>一掃 QR Code 即可下單，無需下載 App和註冊，方便快捷<br/> <br/>與POS完美整合，餐單同步，無需費時管理</td>
                                <td>可能需要下載APP，註冊後才可使用，減低客人使用意慾<br/> <br/>可能需要經較複雜的設定後才可使用，需額外費時管理</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <div className="list-tile">
                    <h4>
                        3. 其他優勝功能
                    </h4>
                    <h5>
                        A) 電子餐牌
                    </h5>
                    <ul>
                        <li>可隨時為餐點上架、下架</li>
                        <li>餐點、配料次序可自由排列，將熱門項目排前，加快下單流程</li>
                        <li>支援其他外賣平台專屬餐單</li>
                    </ul>
                    <h5>
                        B) 開枱、點餐、出單
                    </h5>
                    <ul>
                        <li>顯示每張枱的用餐時間，方便管理座位</li>
                        <li>廚房單有不同字體大小選擇，亦可設定簡稱，方便員工閱讀，加快出餐流程</li>
                        <li>主程式可點餐、取消、更改、後加餐點</li>
                        <li>輸入密碼即可取消帳單或復原已結帳帳單，極速復修錯誤操作</li>
                    </ul>
                    <h5>
                        C) 統計分析
                    </h5>
                    <ul>
                        <li>後台可查閱統計報表</li>
                        {/* <li>提供交易資訊CSV檔下載，以作更多分析</li> */}
                    </ul>
                </div>


                <div className="list-tile">
                    <h4>
                        4. 貼心的售後服務和技術支援
                    </h4>
                    <ul>
                        <li>如硬件有所損壞， 可提供免費備機服務</li>
                        <li>提供客戶熱線、WhatsApp和電郵查詢以協助客戶設定或解決使用時所遇到的問題</li>
                        <li>另外，由於是雲端系統，大部分問題可即時遙距解決，不用等待技術支援上門維修</li>
                    </ul>
                </div>

                <div className="list-tile">
                    <h4>
                        5. 免費體驗餐飲系統
                    </h4>
                    <ul>
                        {/* <li>可免費註冊帳戶，然後下載應用程式，即可自己體驗Caterat餐飲系統</li> */}
                        <li>可致電或透過Whatsapp聯絡我們，預約專人上門進行系統示範或親臨本中心觀看系統示範</li>
                    </ul>
                </div>

                <div className="list-tile">
                    <h4>
                        6. 一星期完成安裝
                    </h4>
                    <ul>
                        <li>無需昂貴的定制硬件，最快可一星期完成安裝和設定</li>
                    </ul>
                </div>

            </div>
        </div>
    );
}

export default Home;