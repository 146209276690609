import { useState } from 'react';
import '../../root.css';
import './Pricing.css';
import '../../App.css';
import backgroundImage from '../../assets/pricing_background.webp';

function Pricing(){
    const [pricingPlan, setPricingPlan] = useState("yearly");
    const handlePricingPlan = (e) => setPricingPlan(e.target.value);
    const plan = {
        monthly: {
            name: "月費計劃",
            price: "599",
            interval: "月",
            descriptions: ["彈性模式", "適合短線生意"],
        },
        yearly:{
            name: "年費計劃",
            price: "5,990",
            interval: "年",
            descriptions: ["平均每月$499", "每年節省$1,198"],
        }
    }
    return (
        <div className="pricing flex-column">
            <div className="img-title-container">
                <div className="img-title-wrapper">
                    <h1>Caterat POS | 系統收費</h1>
                    <h1>一個價錢，享用全部功能</h1>
                </div>
            </div>
            <div className="pricing container">
                <div className="plan-btn">
                    <input type="radio" name="pricing-plan" id="yearly" value="yearly" checked={pricingPlan==="yearly"} onChange={handlePricingPlan}></input>
                    <label for="yearly">
                    <span className="pricing-label first">年費計劃</span>
                    </label>
                    <input type="radio" name="pricing-plan" id="monthly" value="monthly" checked={pricingPlan==="monthly"} onChange={handlePricingPlan}></input>
                    <label for="monthly">
                    <span className="pricing-label last">月費計劃</span>
                    </label>
                </div>
                <div>
                    <div className="plan-card-container">
                        <div className="plan-card">
                            <h3>{plan[pricingPlan].name}</h3>
                            <i className="material-icons-outlined">
                            {pricingPlan==="monthly"? "tapas":"brunch_dining"}
                            </i>
                            <span className="plan-card-price">
                                <span className="dollar-sign">HKD$&nbsp;</span>
                                <span className="currency">{plan[pricingPlan].price}</span>
                                <span className="interval">{`   /${plan[pricingPlan].interval}`}</span>
                            </span>
                            <div className={`${plan[pricingPlan].name&&"highlight"}`}>
                                {plan[pricingPlan].descriptions.map(description=>
                                    <p>{description}<i className="material-icons-outlined">verified</i></p>
                                )}
                                {/* <p className="remark"><a>*需要額外收費</a></p> */}
                            </div>
                            <a href="/demo">
                                <button className="plan-card-btn">立即開店</button>
                            </a>
                        </div>
                        <div className="plan-card desktop-only">
                            <h3>月費計劃</h3>
                            <i className="material-icons-outlined">
                            tapas
                            </i>
                            <span className="plan-card-price">
                                <span className="dollar-sign">HKD$&nbsp;</span>
                                <span className="currency">{599}</span>
                                <span className="interval">{`   /月`}</span>
                            </span>
                            <div>
                            <p className="bold">彈性模式<i className="material-icons-outlined">verified</i></p>
                            <p className="bold">適合短線生意<i className="material-icons-outlined">verified</i></p>
                            {/* <p className="bold">*線上外賣平台<i className="material-icons-outlined">verified</i></p>
                            <p className="remark"><a>*需要額外收費</a></p> */}
                            </div>
                            <a href="/demo">
                                <button className="plan-card-btn">立即開店</button>
                            </a>
                        </div>
                    </div>
                    {/* <h4 id="pricing-remark"><small>*本餐飲系統需要在有網路連線之連網下使用</small></h4> */}
                </div>
                <div className="tvp-description">     
                <h2>預約免費系統示範</h2>
                <h4>可致電或透過Whatsapp聯絡我們，預約專人上門進行系統示範或親臨本中心觀看系統示範。
                <br/></h4>
                </div>
                <div className="tvp-description">            
                <h2>上門培訓、設定菜單和安裝硬件</h2>
                <h4>訂閱成功後，可WhatsApp預約免費上門培訓。
                <br/>如需專人設定全部餐單或透過我們選購和安裝硬件，亦可透過Whatsapp聯絡我們查詢報價。
                <br/></h4>
                </div>
                {/* <div className="tvp-description">            
                <h2>啟用網上外賣自取平台</h2>
                <h4>可隨時啟用網上外賣自取平台以進行外賣遙距點餐和外賣自助點餐。
                <br/>
                <div className="space-separator"/>
                </h4>
                <h4>開啟後，每間餐廳會有獨立的外賣自取平台。
                <br/>
                </h4>
                <div className="space-separator"/>
                <h4>每筆成功在本平台進行的線上收款，本公司只收取 HK$ 1.00* 作服務費。
                <br/>另外，信用卡支付平台會向商戶額外收取服務費，詳情請參閱<a href="https://stripe.com/en-hk/pricing" target="_blank">Stripe官方網站</a>。
                <br/>
                <div className="space-separator"/>
                <small>*每筆信用卡收費會根據Stripe收費而釐定，而本公司每月份的網上外賣自取平台服務費會於下一個月的第一日收取。</small></h4>
                </div> */}
                <div className="tvp-description">
                    <h2>Caterat餐飲系統為TVP 科技券可資助項目</h2>
                    {/* <h4 className="list-item">科技券旨在資助本地非上市企業及機構使用科技服務和方案，以提高生產力或將業務流程升級轉型。</h4> */}
                    {/* <h4 className="list-item">每名申請者最多可獲批六個項目的資助，資助金額上限為60萬元。</h4> */}
                    <h4>Caterat POS 餐飲系統 乃香港創新科技署 (ITC) 認可之TVP 科技券可資助項目，<br/>我們可協助客戶申請，減低開餐廳成本。</h4>
                </div>
            </div>

        </div>
    );
}

export default Pricing;