import '../../root.css';
import './ContactUs.css';
import '../../App.css';

function ContactUs(){
    return (
        <div className="contactus flex-column">
            <div className="container content-grid-5">
                <div className="">
                    <h1>聯絡我們</h1>
                    <br/>
                    <br/>
                    <h2>地址</h2>
                    <br/>
                    <p>
                    Room 1-F08, 7/F., <br/>
                    Block A, Po Yip Building,<br/>
                    62-70 Texaco Road,<br/>
                    Tsuen Wan, Hong Kong
                    </p>
                    <p>新界荃灣德士古道６２－７０號寶業大廈7樓A1-F8室</p>
                    <br/>
                    <br/>
                    <br/>
                    <h2>聯絡電話</h2>
                    <br/>
                    <p>5662 9356</p>
                </div>
                <div className="map-container">
                    <iframe src="https://maps.google.com/maps?width=100%25&amp;height=500&amp;hl=en&amp;q=%E5%AF%B6%E6%A5%AD%E5%A4%A7%E5%BB%88%20%E8%8D%83%E7%81%A3%E6%B2%99%E5%92%80%E9%81%93391-407%E8%99%9F+(R%20CUBE%20APP%20STUDIO%20LTD)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" className="address-map" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </div>
        </div>
    );
}
const AddressMap=()=>{
    return (
        <div className="google-map-code">
          
        </div>
    );
 }
{/* <div style="width: 100%"><iframe width="100%" height="500" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=500&amp;hl=en&amp;q=%E5%AF%B6%E6%A5%AD%E5%A4%A7%E5%BB%88%20%E8%8D%83%E7%81%A3%E6%B2%99%E5%92%80%E9%81%93391-407%E8%99%9F+(R%20CUBE%20APP%20STUDIO%20LTD)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="http://www.gps.ie/">vehicle gps</a></div> */}
export default ContactUs;